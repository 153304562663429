@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    font-family:var(--Poppins-font);
}
:root{
    --Poppins-font: "Poppins", cursive;
}
#persona{
    background-color:darkcyan;
    border-radius: 100%;
    width: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 0 0 0;
    margin: 0 0 0 10;
}
.logo {
    width: 125px;
    margin-left: 20px;
}
#logo {
    width: 125px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.cls-1 {
    fill: #fdfffc;
    stroke-width: 0px;
}
.cls-2 {
    fill: #1D1D1D;
    stroke-width: 0px;
}
.cls-4 {
    fill: #fefffe;
}

.cls-4, .cls-5, .cls-3 {
    stroke-width: 0px;
}

.cls-5 {
    fill: #fdfffc;
}

.cls-3 {
    fill: #fefffd;
}

li{
    margin-left: 10px;
}